import React, { useEffect, useState } from 'react'
import { Container } from 'starterUiComponents'
import getFirebase from '../../../lib/firebase'
import { chakra, Button, Center, Input, Alert, AlertIcon } from '@chakra-ui/react'
import { Link } from 'gatsby'
import ApiFetch from '../../../utils/api'
import { useAuth } from '../../../contexts/AuthContext'

export const RecoverEmail = ({ oobCode, apiKey, onResetTokenExpired }) => {
  const [submitting, setSubmitting] = useState(false)
  const [password, setPassword] = useState("")
  const [email, setEmail] = useState(false)
  const [error, setError] = useState(false)
  const [askForPassword, setAskForPassword] = useState(false)
  const [doDBEmailUpdate, setDoDBEmailUpdate] = useState(false)
  const { currentUser, updateUser } = useAuth()

  // On load, we check email and if successful, we ask for password
  useEffect(() => {
    askForPassword && setAskForPassword(false);
    const firebase = getFirebase({ 'apiKey': apiKey });
    firebase.auth().checkActionCode(oobCode).then((res) => {
      const restoredEmail = res && res['data'] && res['data']['email'] || null;
      if (restoredEmail) {
        setEmail(restoredEmail);
        setAskForPassword(true)
      }
    }).catch((error) => {
      console.error("2 email verification error: ", error)
      onResetTokenExpired && onResetTokenExpired()
    })
  }, [oobCode, apiKey])

  // When password is submitted, we login the user, get current shopData from currentUser, get the token to verify it is a successful logged in user
  // idToken needs to be set also on backend!
  const onPasswordSubmit = (e) => {
    e.preventDefault()
    setSubmitting(true)
    const firebase = getFirebase({ 'apiKey': apiKey });
    firebase.auth().signInWithEmailAndPassword(email, password).then(async (response) => {
      try {
        setDoDBEmailUpdate(true)
      } catch (e) {
        setDoDBEmailUpdate(false)
        console.error("error: ", e)
        setError("An unexpected error happened!")
      } finally {
        setSubmitting(false)
      }
    }).catch(e => {
      setSubmitting(false)
      setDoDBEmailUpdate(false)
      if (e.code === "auth/wrong-password") {
        setError("Wrong password!")
      } else {
        setError("An unexpected error happened!")
      }
      console.error("error: ", e)
    })

  }

  useEffect(() => {
    if (currentUser?.shopData && doDBEmailUpdate) {
      ApiFetch({
        method: 'PUT',
        path: `/auth/email/${email}`,
        body: {
          subnum: currentUser.shopData.sub_num
        }
      }).then(result => {
        setDoDBEmailUpdate(false)
        if (result.success) {
          updateUser({
            ...currentUser,
            email
          })
        }
      }).catch(e => {
        setDoDBEmailUpdate(false)
        console.log("error: ", e)
        setError("An unexpected error happened!")
      })

    }
  }, [currentUser?.shopData, doDBEmailUpdate])

  return (
    <Container size="sm">
      <chakra.h2 className="mb-10 text-center uppercase text-l2">
        Recovering your old email account
      </chakra.h2>
      {askForPassword && (
        <form onSubmit={onPasswordSubmit}>
          <div>
            <Input
              type="password"
              name="password"
              className="mb-2"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="password"
            />
            <Link
              to="/sign-in"
              className="font-bold uppercase text-darkBlue text-md font-heading"
            >
              Go to login
            </Link>
          </div>
          <Center>
            <Button
              variant="lightBlue"
              disabled={submitting}
              mt={4}
              type="submit"
            >
              {`Updat${submitting ? 'ing' : 'e'} password`}
            </Button>
          </Center>
        </form>
      )}
      {error && (
        <Alert status="error" className="mt-4">
          <AlertIcon />
          {error}
        </Alert>
      )}
    </Container>
  )
}
