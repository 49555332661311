import React from 'react'
import { Router as MyRouter } from "@reach/router"
import { FirebaseCustomUpdate } from '../components/auth/firebase/FirebaseCustomUpdate'

const Router = () => {
    return (
        <MyRouter>
            <FirebaseCustomUpdate path="/auth-updates" />
        </MyRouter>
    )
}

export default Router