import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Container } from 'starterUiComponents'
import { Alert, AlertIcon, Button, Center, Input, chakra } from '@chakra-ui/react'
import { Link, navigate } from 'gatsby'
import getFirebase from '../../../lib/firebase'
import ApiFetch from '../../../utils/api'

export const PasswordUpdate = ({ oobCode, apiKey, onResetTokenExpired }) => {
  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState(null)
  const { handleSubmit, register } = useForm()
  const [message, setMessage] = useState('')

  const onSubmit = async (data, e) => {
    setSubmitting(true)
    const { password } = data
    const firebase = getFirebase({ 'apiKey': apiKey });
    error && setError(false)

    // Verify the password reset code is valid.
    firebase.auth().verifyPasswordResetCode(oobCode).then((email) => {
      // Save the new password.
      firebase.auth().confirmPasswordReset(oobCode, password).then((resp) => {
        // sign-in the user directly
        firebase.auth().signInWithEmailAndPassword(email, password).then(async (response) => {
          const idToken = await response.user.getIdToken(true);
          try {
            const result = await ApiFetch({
              method: 'PUT',
              path: `/auth/password`,
              body: {
                email: response.user.email,
                password: password,
                idToken
              }
            })
            if (result.success) {
              setMessage(`Password updated succesfully. You are being redirected to your dashboard`)
              setSubmitting(false)
            } else if (result.error) {
              console.error("result.error.message: ", result.error.message)
              setError("An unexpected error happened!")
              setSubmitting(false)
            }
          } catch (e) {
            console.log("error: ", e)
            setError("An unexpected error happened!")
            setSubmitting(false)
          }
        })
      }).catch((error) => {
        console.error("error: ", error)
        if (error.code === "auth/weak-password") {
          setError("Password should be at least 6 characters long")
        } else {
          setError("An unexpected error happened!")
        }
        setSubmitting(false)
      })
    }).catch(() => {
      onResetTokenExpired && onResetTokenExpired()
      setSubmitting(false)
    })
  }

  React.useEffect(() => {
    if (message) {
      setTimeout(() => {
        setMessage('')
        navigate('/dashboard')
      }, 2000)
    }
  }, [message])


  return (
    <Container size="sm">
      <chakra.h2 className="mb-10 text-center uppercase text-l2">
        Add a new password to your account
      </chakra.h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <Input
            type="password"
            name="password"
            className="mb-2"
            placeholder="password"
            ref={register}
          />
          <Link
            to="/sign-in"
            className="font-bold uppercase text-darkBlue text-md font-heading"
          >
            Go to login
          </Link>
        </div>
        <Center>
          <Button
            variant="lightBlue"
            disabled={submitting || message}
            mt={4}
            type="submit"
          >
            {`Updat${submitting ? 'ing' : 'e'} password`}
          </Button>
        </Center>
      </form>
      {error && (
        <Alert color="white" status="error" className="mt-4">
          {error}
        </Alert>
      )}
      {message && (
        <Alert status="success" className="mt-4">
          <AlertIcon />
          {message}
        </Alert>
      )}
    </Container>
  )
}
