import React, { useEffect } from 'react'
import { Container } from 'starterUiComponents'
import getFirebase from '../../../lib/firebase'
import { chakra } from '@chakra-ui/react'

export const VerifyEmail = ({ oobCode, apiKey, onResetTokenExpired }) => {

  useEffect(() => {
    const firebase = getFirebase({ 'apiKey': apiKey });
    firebase.auth().verifyPasswordResetCode(oobCode).then((info) => {
      firebase.auth().applyActionCode(oobCode).then((resp) => {
      }).catch((error) => {
        console.log("email vverification error: ", error)
        // Code is invalid or expired. Ask the user to verify their email address
        // again.
      });
    }).catch(() => {
      onResetTokenExpired && onResetTokenExpired()
    })
  }, [oobCode, apiKey])


  return (
    <Container size="sm">
      <chakra.h2 className="mb-10 text-center uppercase text-l2">
        Verifying your email account
      </chakra.h2>
    </Container>
  )
}
