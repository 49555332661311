import React, { useState, useEffect } from 'react'
import { Container } from 'starterUiComponents'
import { Button, Center, chakra } from '@chakra-ui/react'
import { Link, navigate } from 'gatsby'
import getFirebase from '../../../lib/firebase'
import Loader from '../../ui-components/Loader'
import { PasswordUpdate } from './PasswordUpdate'
import { VerifyEmail } from './VerifyEmail'
import { RecoverEmail } from './RecoverEmail'

// Main manager to manage the 3 different possible views to update password, verify email or update email from Firebase
export const FirebaseCustomUpdate = (props) => {
  const [state, setState] = useState({
    mode: null,
    error: null,
    resetTokenExpired: false,
    queryParams: {
      oobCode: null,
      apiKey: null,
      mode: null,
    }
  })

  // Check if token expired or it is not reset password mode
  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const mode = params.get("mode")
    const oobCode = params.get("oobCode")
    const apiKey = params.get("apiKey")

    const isAllowedMode = ["resetPassword", "recoverEmail", "verifyEmail"].indexOf(mode) >= 0
    if (!isAllowedMode) {
      navigate('/404')
      return
    }
    const firebase = getFirebase({ 'apiKey': apiKey });
    firebase.auth().verifyPasswordResetCode(oobCode).then((info) => {
      setState(state => ({
        ...state,
        mode: mode,
        resetTokenExpired: false,
        queryParams: {
          mode,
          oobCode,
          apiKey,
        }
      }))
    }).catch(() => {
      setState(state => ({ ...state, resetTokenExpired: true, mode: isAllowedMode ? mode : null }))
    })
  }, [props.location.search])

  if (!state.mode) {
    return <Loader message="Checking token expiricy" />
  }

  if (state.resetTokenExpired) {
    return (
      <Container size="sm">
        <chakra.h2 className="mb-10 text-center uppercase text-l2">
          Token expired
        </chakra.h2>
        {state.mode === "resetPassword" &&
          (
            <form className="text-center uppercase text-l2">
              <div className="flex justify-center">
                <Button as={Link} to="/reset-password">
                  Request new password
                </Button>
              </div>
            </form>
          )
        }
        {state.mode !== "resetPassword" &&
          (
            <chakra.h3 className="mb-10 text-center uppercase text-l">
              Your request token for this action has expired. Please try again or contact support.
            </chakra.h3>
          )
        }
      </Container>
    )
  }

  if (state.mode) {
    switch (state.mode) {
      case "resetPassword":
        return <PasswordUpdate {...state.queryParams} onResetTokenExpired={() => {
          setState(state => ({ ...state, resetTokenExpired: true }))
        }} />
      case "verifyEmail":
        return <VerifyEmail {...state.queryParams} onResetTokenExpired={() => {
          setState(state => ({ ...state, resetTokenExpired: true }))
        }} />
      case "recoverEmail":
        return <RecoverEmail {...state.queryParams} onResetTokenExpired={() => {
          setState(state => ({ ...state, resetTokenExpired: true }))
        }} />
    }
  }
}


